import React, { useState, useEffect } from 'react';
import { Fighter } from 'models/Fighter';
import { Event, Fight } from 'models/Event';
import { BFFSection } from 'models/BFF';
import HomeSeasonsWidget from 'components/home/HomeSeasonsWidget';
import { Post } from 'models/Post';
import { getTotalPicksPercentage } from 'helpers/FightHelper';
import LatestNewsSection from 'components/home/LatestNewsSection';
import MMACalendarWidget from 'components/home/MMACalendarWidget';
import FighterSearchWidget from 'components/home/FighterSearchWidget';
import AppPredictionWidget from 'components/home/AppPredictionWidget';
import { ArticleItem } from 'components/shared/ArticleItem';
import { assembleEdition, Edition } from 'models/Edition';
import { EditionItem } from 'components/shared/EditionItem';
import HomeTournamentsWidget from 'components/home/HomeTournamentsWidget';
import { ArticleItemCompact } from 'components/shared/ArticleItemCompact';
import { useRouter } from 'next/router';
import { Analytics } from 'components/Analytics';
import { EventForLogin } from 'helpers/Events';
import RedirectModal from 'components/Redirect';
import Head from 'next/head';

type Props = {
  featuredEvent: Event;
  featuredFight: Fight;
  events: Event[];
  fighters: Fighter[];
  leagueSection: BFFSection;
  tournamentSection: BFFSection;
  posts: Post[];
  newsLetterEditions: Edition[];
  lastUpdated: number;
};

const Home = ({ featuredEvent, featuredFight, events, fighters, leagueSection, tournamentSection, lastUpdated, posts, newsLetterEditions }: Props): JSX.Element => {
  const [hasRedirect, setHasRedirect] = useState(false);

  const [redirectMessage, setRedirectMessage] = useState('');
  const [redirectPath, setRedirectPath] = useState('');

  const { query, isReady, replace } = useRouter();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    checksForPostLogin();
  }, [isReady]);

  const checksForPostLogin = async () => {
    const status = !!query.oauth_success ? 'success' : 'failure';
    const method = query.method;
    if (method) {
      try {
        const {
          user: { creation },
        } = await (await fetch(`/api/v1/user/minimum`)).json();
        const currentEpoch = Math.floor(new Date().getTime() / 1000);
        const createdLessThanAMinuteAgo = creation > currentEpoch - 60;
        if (createdLessThanAMinuteAgo) {
          Analytics.track(`interact_signup_complete`, { method, status });
        } else {
          // Two events to match mobile clients.
          Analytics.track(`interact_login_complete`, { method, status });
          Analytics.track(`interact_login_with_${method}`, { method, status });
        }
      } catch (err) {
        // No-op, likely an authentication error, 500s will be caught by backend.
      }
      // Ensures all sessions and cookies are cleared if there is a failed login in attempt
      if (query.oauth_success && Number(query.oauth_success) == 0) {
        Analytics.track(`view_login_failed_${method}`, { method });
        alert('Your login attempt was unsuccessful, if the issue continues to persist please contact us at support@verdictmma.com');
        await fetch(`/api/v1/logout`);
      }
      Analytics.track(`login_with_${method}_${status}`);
      const redirect = sessionStorage.getItem('successfulLoginRedirect') || '';
      if (redirect.length > 0) {
        setHasRedirect(true);
        sessionStorage.removeItem('successfulLoginRedirect');
        window.location.href = redirect;
      } else if (query.referral) {
        setRedirectMessage("You're almost there! One more step to complete your referral. Deposit $10 and both you and your friend will earn $10.");
        setRedirectPath(`/refer-friend/accept/${query.referral}`);
        setHasRedirect(true);
      } else {
        replace('/', undefined, { shallow: true });
        const event = new CustomEvent(EventForLogin);
        window.dispatchEvent(event);
      }
    }
  };

  let topFeaturedArticle = null;
  let sideFeaturedArticles = [];
  let bottomFeaturedArticles = [];
  let moreSideArticles = [];
  let bottomArticles = [];

  if (posts && posts.length > 0) {
    topFeaturedArticle = posts[0];
    sideFeaturedArticles = posts.slice(1, 3);
    bottomFeaturedArticles = posts.slice(3, 6);
    moreSideArticles = posts.slice(6, 11);
    bottomArticles = posts.slice(11);
  }

  const onConfirmRedirect = () => {
    window.location.href = redirectPath;
  };

  const title = `Verdict MMA, UFC and MMA News, Picks, and Live Scoring`;
  const description = `UFC and MMA fight picks and live round scoring platform driven by a passionate community. Available for the UFC and PFL. Now discussing, ${posts && posts.length > 0 ? posts[0].title : ''}`;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={posts && posts.length > 0 ? posts[0].imageURL : ''} />
        <meta property="og:type" content="website" />
      </Head>
      <RedirectModal showRedirectModal={hasRedirect} text={redirectMessage} onConfirm={onConfirmRedirect} onCancel={() => setHasRedirect(false)} />
      <div className="flex flex-col items-center py-4 w-full">
        {/* This is useful for debugging breakpoints. */}
        {/* <div className="w-full col-span-full h-2 bg-blue-600 sm:bg-red-600 md:bg-yellow-600 lg:bg-purple-600  xl:bg-gray-700"></div> */}
        <div className="max-w-7xl w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:grid-rows-home gap-4 px-4 pb-20">
          {/* Section One - News */}
          <div className="col-span-1 row-span-1 md:row-start-1">
            <ArticleItem post={sideFeaturedArticles[0]} isPreviewHidden={true} />
          </div>
          <div className="col-span-1 md:row-start-2">
            <ArticleItem post={sideFeaturedArticles[1]} isPreviewHidden={true} />
          </div>
          {topFeaturedArticle && (
            <div className="col-span-1 row-span-1 col-start-1 row-start-1 md:row-start-1 md:row-span-2 md:col-span-2 md:col-start-2">
              <ArticleItem post={topFeaturedArticle} isPreviewHidden={false} />
            </div>
          )}
          <div className="col-span-1 md:row-start-3">
            <ArticleItem post={bottomFeaturedArticles[0]} isPreviewHidden={true} />
          </div>
          <div className="col-span-1 md:row-start-3">
            <ArticleItem post={bottomFeaturedArticles[1]} isPreviewHidden={true} />
          </div>
          <div className="col-span-1 md:row-start-3">
            <ArticleItem post={bottomFeaturedArticles[2]} isPreviewHidden={true} />
          </div>
          <div className="order-last md:order-none flex flex-col col-span-1 sm:col-span-2 lg:col-span-3 gap-4">
            {newsLetterEditions && newsLetterEditions.length > 0 && newsLetterEditions[0] && <EditionItem edition={newsLetterEditions[0]} isCompressed={true} />}
            <FighterSearchWidget trendingFighters={fighters} />
            {bottomArticles &&
              bottomArticles.length > 0 &&
              bottomArticles.map((post, index) => (
                <div key={`bottom-posts-${index}`} className="grow">
                  <ArticleItemCompact post={post} isCompact={true} />
                </div>
              ))}
          </div>
          <div className="flex flex-col col-span-1 sm:col-span-2 md:col-span-1 lg:row-start-1 gap-4">
            <LatestNewsSection posts={moreSideArticles} />
            <MMACalendarWidget events={events} />
            <HomeTournamentsWidget section={tournamentSection} />
            <AppPredictionWidget event={featuredEvent} fight={featuredFight} lastUpdated={lastUpdated} />
            <HomeSeasonsWidget section={leagueSection} />
          </div>
        </div>
      </div>
    </>
  );
};

export async function getStaticProps() {
  const url = process.env.NEXT_PUBLIC_VD_URL;
  const secret = process.env.VD_BUILD_SECRET;
  const res = await fetch(`${url}/api/v1/home`, { headers: { 'x-verdict-auth-header': secret } });

  const props = {} as Props;

  try {
    const result = await res.json();
    props.events = result.events;
    props.fighters = result.fighters;
    props.leagueSection = result.leagueSection;
    props.tournamentSection = result.tournamentSection;
  } catch (error) {
    console.log(error);
  }

  try {
    const verdictURL = process.env.NEXT_PUBLIC_VD_URL;
    const secret = process.env.VD_BUILD_SECRET;

    const articlesResponse = await fetch(`${verdictURL}/api/v1/articles?limit=30`, { headers: { 'x-verdict-auth-header': secret } });
    const response = await articlesResponse.json();
    props.posts = response.articles;
  } catch (error) {
    console.log(error);
  }

  try {
    const strapiResponse = await fetch(`${process.env.VD_STRAPI_URL}/api/editions?populate=*&sort[0]=edition%3Adesc`, { headers: { Authorization: `Bearer ${process.env.VD_STRAPI_TOKEN}` } });
    const strapiData = await strapiResponse.json();
    props.newsLetterEditions = strapiData.data.map(assembleEdition);
  } catch (error) {
    console.log(error);
  }

  const eventResponse = await fetch(`${url}/api/v1/event/upcoming`, { headers: { 'x-verdict-auth-header': secret } });

  try {
    const data = await eventResponse.json();
    const featuredEvent = data && data.event ? data.event : null;
    props.featuredEvent = featuredEvent;
    const fights = featuredEvent && featuredEvent.fights ? featuredEvent.fights : [];
    for (let i = 0; i < fights.length; i++) {
      const fight = fights[i];
      const result = await fetch(`${url}/api/v1/rounds?eventID=${featuredEvent.eventID}&fightNumber=${fight.fightNumber}&userID=1`, { headers: { 'x-verdict-auth-header': secret } });
      const json = await result.json();
      if (result.status >= 200 && result.status < 300 && json.rounds && json.roundSummary) {
        const totalPicksPercentage = getTotalPicksPercentage(json);
        fight.roundsResponse = json;
        fight.totalPicksPercentage = totalPicksPercentage;
      }
    }
    props.featuredFight = fights.length > 0 ? fights[fights.length - 1] : null;
  } catch (error) {
    console.log(error);
  }

  props.lastUpdated = Date.now();

  return {
    props,
    revalidate: process.env.NEXT_PUBLIC_VD_IS_PRODUCTION ? 60 * 60 : 1,
  };
}

export default Home;
