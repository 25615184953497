import React from 'react';
import Button from './Button';

export enum MarketingButtonType {
  Tournaments,
  League,
  FreeGame,
  Fighter1,
  Fighter2,
}

interface Props {
  className?: string;
  buttonType?: MarketingButtonType;
  children: JSX.Element | JSX.Element[] | string;
  onClick?: () => void;
  inverted: boolean;
  isLoading?: boolean;
}

const MarketingButton = ({ className = '', children, buttonType, inverted, isLoading = false, onClick, ...props }: Props) => {
  let style = '';
  let bottomTrimStyle = '';
  if (buttonType == MarketingButtonType.Tournaments) {
    style += inverted ? 'bg-gray-700 text-tournament-yellow border-gray-900 border-3 hover:bg-gray-900' : ' bg-tournament-yellow text-gray-800 border-tournament-gold border-3 hover:bg-tournament-gold';
    bottomTrimStyle = 'bg-gray-300';
  } else if (buttonType == MarketingButtonType.League) {
    const colorClass = inverted ? ' bg-seasons-blueDark border-blue-base hover:bg-blue-base' : ' bg-blue-base border-seasons-blueDark hover:bg-seasons-blueDark';
    style += ` text-white border-3 ${colorClass}`;
    bottomTrimStyle = 'bg-gray-300';
  } else if (buttonType == MarketingButtonType.FreeGame) {
    style += ' bg-gray-800 text-white border-gradient-r hover:bg-gradient-r';
    bottomTrimStyle = 'bg-gradient-to-r from-red-base to-blue-base';
  } else if (buttonType == MarketingButtonType.Fighter1) {
    style += '  border-3 bg-red-base border-red-dark text-white hover:bg-red-dark';
    bottomTrimStyle = 'bg-gray-300';
  } else if (buttonType == MarketingButtonType.Fighter2) {
    style += '  border-3 bg-blue-base border-seasons-blueDark text-white hover:bg-seasons-blueDark';
    bottomTrimStyle = 'bg-gray-300';
  }

  return (
    <div className={`${className} flex flex-col items-stretch`}>
      <Button className={`h-16 font-sansCondensed font-bold ${style}`} isCustom={true} isLoading={isLoading} onClick={onClick} {...props}>
        {children}
      </Button>
      <div className={`mx-1 h-1 ${bottomTrimStyle} rounded-b-md`}></div>
    </div>
  );
};

MarketingButton.displayName = 'MarketingButton';
export default MarketingButton;
