import React from 'react';
import { Fighter } from 'models/Fighter';
import FighterSearch from 'components/fighter/FighterSearch';

type FightersSearchProp = {
  trendingFighters: Fighter[];
};

const FighterSearchWidget = ({ trendingFighters }: FightersSearchProp): JSX.Element => {
  return (
    <div className="flex rounded bg-white w-full h-full flex-col border border-gray-200">
      <div className="w-full flex leading-non pt-4 px-4 font-medium font-sans text-lg text-gray-900">Search Fighter</div>
      <FighterSearch trendingFighters={trendingFighters} />
    </div>
  );
};

export default FighterSearchWidget;
