import React from 'react';
import Link from 'next/link';
import { Event, Fight } from 'models/Event';
import { CircleImage } from 'components/socialAssets/graphics/CircleIcons';
import ProgressBar from 'components/socialAssets/graphics/ProgressBar';
import dayjs from 'dayjs';
import { getRoundedNumber } from 'helpers/AssetHelper';
import MarketingButton, { MarketingButtonType } from 'components/MarketingButton';

type AppPredictionProp = {
  event: Event;
  fight: Fight;
  lastUpdated: number;
};

const AppPredictionWidget = ({ event, fight, lastUpdated }: AppPredictionProp): JSX.Element => {
  return (
    <div className="flex rounded bg-white w-full flex-col border border-gray-200 p-4">
      <div className="w-full flex leading-non font-medium font-sans text-lg text-gray-900">Verdict Prediction</div>
      <div className="mt-6">
        {event && fight && (
          <div key={fight.fightNumber} className="bg-white w-full">
            <div className="h-full flex flex-col">
              <div className={`flex justify-evenly items-center`}>
                <CircleImage
                  imageSrc={fight.fighter1.fighterImageURL}
                  imageClass="aspect-square w-16 h-16 border-red-base border-4"
                  label={`${fight.fighter1.fighterFirstName}\n${fight.fighter1.fighterLastName}`}
                  labelClass={`text-center pt-1 text text-red-base font-medium capitalize font-sans whitespace-pre-line leading-none`}
                  alt={`${fight.fighter1.fighterFirstName} ${fight.fighter1.fighterLastName}`}
                />
                <div className="font-medium whitespace-pre-line text-gray-400 text-sm leading-none pt-4">vs</div>
                <CircleImage
                  imageSrc={fight.fighter2.fighterImageURL}
                  imageClass="aspect-square w-16 h-16 border-blue-base border-4"
                  label={`${fight.fighter2.fighterFirstName}\n${fight.fighter2.fighterLastName}`}
                  labelClass={`text-center pt-1 text text-blue-base font-medium capitalize font-sans whitespace-pre-line leading-none`}
                  alt={`${fight.fighter2.fighterFirstName} ${fight.fighter2.fighterLastName}`}
                />
              </div>
              <div className="w-full mt-6">
                <div className="flex flex-col items-center justify-center">
                  <div className="w-full flex flex-row items-center justify-between">
                    <div className="text-red-base font-medium font-sans placeholder-red-base">{`${getRoundedNumber(fight.totalPicksPercentage[0], 1)}%`}</div>
                    <div className="flex items-center text-center text-sm text-scorecard-roundBlack font-bold font-sans">{'Global Pick Percentage'}</div>
                    <div className="text-blue-base font-medium font-sans placeholder-blue-base">{`${getRoundedNumber(fight.totalPicksPercentage[1], 1)}%`}</div>
                  </div>
                  <div className="w-full mt-2 flex flex-row items-center justify-center px-0.5">
                    <div className="flex w-full">
                      <ProgressBar
                        numberOfSections={1}
                        amountFilled={getRoundedNumber(fight.totalPicksPercentage[0], 1) / 100}
                        progressColorClass="bg-red-base"
                        remainderColorClass="bg-scorecard-roundLightGray"
                        inverted={true}
                        className="h-4 w-1/2"
                      />
                      <div className="w-1.5" />
                      <ProgressBar
                        numberOfSections={1}
                        amountFilled={getRoundedNumber(fight.totalPicksPercentage[1], 1) / 100}
                        progressColorClass="bg-blue-base"
                        remainderColorClass="bg-scorecard-roundLightGray"
                        inverted={false}
                        className="h-4 w-1/2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full mt-6 flex-col gap-2 items-center justify-center">
                <div className="text-center text-sm text-scorecard-roundBlack font-bold font-sans">Make Your Pick</div>
                <Link href={`/event/${event.eventSlug}/fight/${fight.fightSlug}?pick=1`} passHref legacyBehavior>
                  <div className="w-full">
                    <MarketingButton buttonType={MarketingButtonType.Fighter1} inverted={false}>
                      <div>
                        {fight.fighter1.fighterFirstName} {fight.fighter1.fighterLastName}
                      </div>
                    </MarketingButton>
                  </div>
                </Link>
                <Link href={`/event/${event.eventSlug}/fight/${fight.fightSlug}?pick=1`} passHref legacyBehavior>
                  <div className="w-full">
                    <MarketingButton buttonType={MarketingButtonType.Fighter2} inverted={false}>
                      <div>
                        {fight.fighter2.fighterFirstName} {fight.fighter2.fighterLastName}
                      </div>
                    </MarketingButton>
                  </div>
                </Link>
              </div>
            </div>
            <div className="flex flex-row mt-6 w-full">
              <div className="text-gray-500 font-medium text-sm">Updated {dayjs().to(dayjs(lastUpdated))}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppPredictionWidget;
