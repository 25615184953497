import React from 'react';
import Modal from 'components/Modal';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import Button from './Button';

interface RedirectProp {
  showRedirectModal: boolean;
  text?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  savedRecord?: Record<string, string>;
}

const RedirectModal = ({ showRedirectModal, text, onCancel, onConfirm }: RedirectProp): JSX.Element => {
  return (
    <Modal open={showRedirectModal}>
      <div className="flex flex-col justify-center items-center">
        <ArrowsRightLeftIcon className="w-12 h-12 text-gray-200 dark:text-gray-300 fill-black" />
        <div className="mt-4">{text ? text : 'Redirecting you back to where you left off...'}</div>
        {onConfirm && (
          <div className="w-full mt-4 flex flex-row space-x-2">
            {onCancel && (
              <Button onClick={onCancel} className={`h-11 text-center justify-center w-full`} isSecondary={true}>
                Cancel
              </Button>
            )}
            <Button onClick={onConfirm} className={`h-11 text-center justify-center w-full`} isPrimary={true}>
              OK
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RedirectModal;
