import React from 'react';
import Link from 'next/link';
import { ImageWrapped } from './ImageWrapped';
import { Edition } from 'models/Edition';
import { descriptionFromMarkdown } from '../../helpers/seo';

type EditionItemProp = {
  edition: Edition;
  isCompressed?: boolean;
};

export const EditionItem = ({ edition, isCompressed = false }: EditionItemProp): JSX.Element => {
  const widthClass = isCompressed ? 'w-24' : 'w-24 md:w-36';
  const heightClass = isCompressed ? 'h-24' : 'h-24 md:h-36';
  const baseTextClass = isCompressed ? 'text-base' : 'text-base sm:text-md md:text-lg';
  const smTextClass = isCompressed ? 'text-sm' : 'text-sm md:text-base';

  return (
    <Link href={edition.url} passHref>
      <div className={`bg-white flex flex-col cursor-pointer hover:bg-gray-100 border border-gray-200 rounded overflow-hidden p-4 gap-4 font-sans`}>
        <div className="flex flex-row justify-center items-center gap-4">
          <div className={`flex-none ${widthClass} ${heightClass} relative`}>
            <div className="absolute z-10 bg-white/80 p-2 w-full h-full flex items-center justify-center">
              <img alt="OverUnder" className="w-full md:h-32 self-center" src="/img/overunder_logo_full.svg" />
            </div>
            <ImageWrapped shouldShimmer className="rounded border border-gray-600" src={edition.imageURL} alt={edition.subject} />
          </div>
          <div className={`flex flex-col gap-0.5 sm:gap-1 justify-start`}>
            <div className={`flex justify-between w-full text-xs ${baseTextClass}`}>
              <div className="text-red pr-1 font-bold font-sans">OverUnder {edition.edition}</div>
              <div className="text-gray-500">{edition.date}</div>
            </div>
            <div className={`flex-none ${baseTextClass} text-gray-600 font-bold md:text-gray-800 md:font-medium line-clamp-2`}>{edition.subject}</div>
            <div className={`flex-shrink text-gray-500 ${smTextClass} line-clamp-2 md:line-clamp-3`}>{descriptionFromMarkdown(edition.content, 400)}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};
