import React from 'react';
import { BFFSection } from 'models/BFF';
import RowCell from 'components/bff/RowCell';
import MarketingButton, { MarketingButtonType } from 'components/MarketingButton';
import Button from 'components/Button';

type Props = {
  section: BFFSection;
  isNonMarketing?: boolean;
};

const HomeSeasonsWidget = ({ section, isNonMarketing = false }: Props): JSX.Element => {
  return (
    <div className="w-full">
      <div className="flex flex-col rounded border border-gray-200 divide-y divide-gray-200 bg-white">
        <div className="m-4 font-medium font-sans text-gray-800 leading-none">Seasons & Leagues</div>
        {section.rows.map((row, index) => (
          <RowCell key={`row-${index}-${row.type}`} row={row} isCompact={section.isCompact} isMobile={false} />
        ))}
        <div className="w-full p-4">
          {section.action && (
            <a href={section.action.link}>
              {isNonMarketing ? (
                <Button isLargeRoundedFull className="w-full">
                  {section.action.title}
                </Button>
              ) : (
                <MarketingButton className="" buttonType={MarketingButtonType.League} inverted={false}>
                  {section.action.title}
                </MarketingButton>
              )}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeSeasonsWidget;
