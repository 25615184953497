import React from 'react';
import { Event } from 'models/Event';
import HomeEventSection from './HomeEventSection';

type CalendarProp = {
  events: Event[];
};

const MMACalendarWidget = ({ events }: CalendarProp): JSX.Element => {
  return (
    <div className="flex rounded bg-white w-full flex-col border border-gray-200">
      <div className="w-full flex leading-non p-4 font-medium font-sans text-lg text-gray-900 border-b border-gray-200">MMA Calender</div>
      {events.map((event, index) => (
        <div className="flex flex-col" key={index}>
          <HomeEventSection event={event} />
        </div>
      ))}
    </div>
  );
};

export default MMACalendarWidget;
