import React from 'react';
import Link from 'next/link';
import { ImageWrapped } from 'components/shared/ImageWrapped';
import { Post } from 'models/Post';
import { CommentCount } from 'components/comments/CommentCount';
import { getFormattedDateString } from 'helpers/dayjs';

type NewsProp = {
  posts: Post[];
};

const LatestNewsSection = ({ posts }: NewsProp): JSX.Element => {
  let topFeaturedArticle = null;
  let topArticleDateString = '';
  let bottomArticles = [];

  if (posts && posts.length > 0) {
    topFeaturedArticle = posts[0];
    topArticleDateString = getFormattedDateString(topFeaturedArticle.date);
    bottomArticles = posts.slice(1);
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex rounded bg-white w-full h-full flex-col border border-gray-200 font-sans">
        <div className="flex-none mt-4 mx-4 w-full flex leading-none font-medium font-sans text-lg text-gray-900">More from Verdict</div>
        <div className="flex-1 mt-2 flex flex-col w-full h-full divide-y divide-gray-200">
          {topFeaturedArticle && (
            <Link href={topFeaturedArticle.url} passHref legacyBehavior>
              <div className="flex-none cursor-pointer hover:bg-gray-100 p-4 flex flex-col w-full gap-2">
                <div className="relative">
                  <div className="aspect-w-2 aspect-h-1">
                    <div>
                      <ImageWrapped shouldShimmer className={`lg:absolute rounded`} src={topFeaturedArticle.imageURL} alt={topFeaturedArticle.title} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col h-full justify-center gap-1">
                  <a className="cursor-pointer md:text-ellipsis text-sm text-gray-600 font-bold md:text-gray-800 md:font-medium">{topFeaturedArticle.title}</a>
                  <div className={`flex-none text-gray-500 text-xs font-medium flex flex-row flex-wrap items-center gap-1`}>
                    {topFeaturedArticle.authors && topFeaturedArticle.authors.length > 0 && topFeaturedArticle.authors[0].name && (
                      <div>
                        {topFeaturedArticle.authors.map(({ name }, i) => (
                          <span key={i}>
                            {name}
                            {i < topFeaturedArticle.authors.length - 1 ? ', ' : ''}
                          </span>
                        ))}
                        <span className="ml-1">|</span>
                      </div>
                    )}
                    {topArticleDateString && topArticleDateString.length !== 0 && (
                      <div className="line-clamp-1">
                        <span>{topArticleDateString}</span>
                        <span className="ml-1">|</span>
                      </div>
                    )}
                    <CommentCount count={topFeaturedArticle.commentCount} />
                  </div>
                </div>
              </div>
            </Link>
          )}
          {bottomArticles &&
            bottomArticles.map(({ title, url, imageURL, authors, date, commentCount }, index) => {
              const dateString = getFormattedDateString(date);
              return (
                <Link key={title} href={url} passHref legacyBehavior>
                  <div className={`flex-1 cursor-pointer hover:bg-gray-100 p-4 flex flex-row items-center w-full gap-2 ${index > 3 ? 'lg:hidden' : ''}`}>
                    <div>
                      <div className="relative w-16 h-16">
                        <ImageWrapped shouldShimmer className={`w-full h-full rounded`} src={imageURL} alt={title} />
                      </div>
                    </div>
                    <div className="flex flex-col h-full justify-center gap-1">
                      <a className="md:text-ellipsis text-sm text-gray-600 font-bold md:text-gray-800 md:font-medium line-clamp-2">{title}</a>
                      <div className={`flex-none text-gray-500 text-xs font-medium flex flex-row flex-wrap items-center gap-1`}>
                        {authors && authors.length > 0 && authors[0].name && (
                          <div>
                            {authors.map(({ name }, i) => (
                              <span key={i}>
                                {name}
                                {i < authors.length - 1 ? ', ' : ''}
                              </span>
                            ))}
                            <span className="ml-1">|</span>
                          </div>
                        )}
                        {dateString && dateString.length !== 0 && (
                          <div>
                            <span>{dateString}</span>
                            <span className="ml-1">|</span>
                          </div>
                        )}
                        <CommentCount count={commentCount} />
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LatestNewsSection;
