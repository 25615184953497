import React from 'react';
import { Fighter } from 'models/Fighter';
import { CircleFighterImage } from '../socialAssets/graphics/CircleIcons';

type FighterSectionProps = {
  fighter: Fighter;
  reverse?: boolean;
  textClass?: string;
  fighterWinOdds?: string;
  fighterImageDisabled?: boolean;
  isResponsive?: boolean;
};

const FighterSection = ({ fighter, textClass, reverse = false, fighterWinOdds, fighterImageDisabled = false, isResponsive = false }: FighterSectionProps): JSX.Element => {
  const fullFighterName = fighter ? `${fighter.fighterFirstName} ${fighter.fighterLastName}` : '';
  const compressedFighterName = fullFighterName.length > 14 ? `${fighter?.fighterFirstName && fighter?.fighterFirstName.length > 0 ? `${fighter?.fighterFirstName.charAt(0)} ` : ''}${fighter?.fighterLastName}` : fullFighterName;

  const winsAmount = fighter?.winsTotal;
  const lossesAmount = fighter?.lossTotal;
  const drawsAmount = fighter?.draws;
  const recordAvailable = winsAmount && lossesAmount;
  const recordString = recordAvailable ? `${winsAmount} - ${lossesAmount} - ${drawsAmount}` : '';

  return (
    <div className={`flex ${reverse ? 'flex-row-reverse' : 'flex-row'} w-full gap-2 items-center font-sans ${isResponsive ? 'p-2 lg:p-4' : 'p-4'}`}>
      {fighter ? (
        <>
          <CircleFighterImage
            isDisabled={fighterImageDisabled}
            fighter={fighter}
            imageClass={`aspect-square ${isResponsive ? 'w-10 h-10 lg:w-16 lg:h-16' : 'w-16 h-16'}`}
            badgeClass={isResponsive ? 'w-4 h-4 lg:w-6 lg:h-6' : 'w-5 h-5 md:w-6 md:h-6'}
          />
          <div className="flex flex-col justify-center align-middle">
            <div className={`flex ${reverse ? 'flex-row-reverse text-end' : 'flex-row text-start'} items-center ${isResponsive ? 'lg:h-3 lg:gap-1' : 'h-3 gap-1'}`}>
              <div style={{ width: '18px', borderRadius: '1px' }} className={`${isResponsive ? 'hidden lg:block lg:h-3' : 'h-3'}`}>
                <img style={{ width: '18px', borderRadius: '1px' }} className={`bg-gray-400 h-3`} src={fighter.flagSrc ?? ''} alt="" />
              </div>
              <div className={`${fighter.nickname ? 'text-gray-600' : 'text-gray-400'} font-normal line-clamp-1 ${isResponsive ? 'text-xs lg:text-sm' : 'text-sm'}`}>{fighter.nickname || ''}</div>
            </div>
            <div className={`flex gap-1 items-center ${reverse ? 'flex-row-reverse text-end' : 'flex-row text-start'}`}>
              <div className={`font-medium line-clamp-1 ${textClass != null ? textClass : 'text-gray-800'} ${isResponsive ? 'text-sm lg:text-base' : 'text-base'}`}>{compressedFighterName}</div>
              {fighterWinOdds && <div className={`flex-none text-xs font-bold text-gray-400 line-clamp-1`}>{fighterWinOdds}</div>}
            </div>
            <div className="">
              <div className={`${recordAvailable ? 'text-gray-600' : 'text-gray-400'} font-normal line-clamp-1  ${reverse ? 'text-end' : 'text-start'} ${isResponsive ? ' text-xxs leading-none lg:leading-normal lg:text-sm' : 'text-sm'}`}>
                {recordString}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FighterSection;
