import React from 'react';
import { Fighter } from 'models/Fighter';
import FighterSection from './FighterSection';

type Props = {
  fighter: Fighter;
  hasBorder: boolean;
};

const FighterCell = ({ fighter, hasBorder }: Props): JSX.Element => {
  return (
    <a href={fighter?.fighterCardURL ?? ''} target="_blank" rel="noopener noreferrer">
      <div className={`w-full bg-white cursor-pointer hover:bg-gray-100 ${hasBorder ? 'border-gray-200 border border-gray-200 rounded' : ''}`}>
        <FighterSection fighter={fighter} reverse={false} fighterImageDisabled={true} />
      </div>
    </a>
  );
};

export default FighterCell;
