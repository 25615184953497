import React from 'react';
import Link from 'next/link';
import { Event } from 'models/Event';
import dayjs from 'helpers/dayjs';

type HomeEventSectionProp = {
  event: Event;
};

const HomeEventSection = ({ event }: HomeEventSectionProp): JSX.Element => {
  return (
    <Link href={`/event/${event.eventSlug}`} passHref legacyBehavior>
      <div className="flex flex-col p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100">
        <div className="font-medium font-sans text-base text-gray-600">{event.eventTitle}</div>
        <div className="font-medium font-sans text-gray-400 text-sm">
          <span className="text-red-base">{event.eventShortTitle}</span> • {dayjs.unix(event.eventDate).format('MMMM D, YYYY')}
        </div>
      </div>
    </Link>
  );
};

export default HomeEventSection;
