import React, { forwardRef } from 'react';
import Link from 'next/link';

interface Props extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  href: string;
  isMobile: boolean;
}

const MobileLink = forwardRef(({ children, href, isMobile, ...props }: Props) => {
  if (!isMobile) {
    return (
      <Link href={href} passHref>
        {children}
      </Link>
    );
  }
  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
});

MobileLink.displayName = 'MobileLink';

export default MobileLink;
