import React from 'react';
import { Post } from 'models/Post';
import { ImageWrapped } from './ImageWrapped';
import { CommentCount } from 'components/comments/CommentCount';
import MobileLink from './MobileLink';
import { getFormattedDateString } from 'helpers/dayjs';
import { descriptionFromMarkdown } from '../../helpers/seo';

type Props = {
  isPreviewHidden?: boolean;
  post: Post;
  isPrimarilyMobile?: boolean;
  hasBorders?: boolean;
  isMinified?: boolean;
};

// Adapted from: https://tailwindcomponents.com/component/politico-clone
export const ArticleItem = ({ post: { authors, imageURL, title, url, commentCount, content, date }, isPreviewHidden = true, isPrimarilyMobile = false, hasBorders = true, isMinified = false }: Props): JSX.Element => {
  const dateString = getFormattedDateString(date);

  return (
    <MobileLink href={url} isMobile={isPrimarilyMobile}>
      <div className={`h-full flex flex-col cursor-pointer ${hasBorders ? 'bg-white md:relative border border-gray-200 rounded overflow-clip' : 'hover:bg-gray-100'} font-sans`}>
        <div className={`relative ${hasBorders ? '' : 'mt-4 mx-4'}`}>
          {/* This is a hack to allow the top and bottom padding to exist without extra whitespace */}
          <div className={`${hasBorders ? 'md:aspect-w-3 md:aspect-h-2 aspect-w-2 aspect-h-1' : 'aspect-w-2 aspect-h-1'}`}>
            <div>
              <ImageWrapped shouldShimmer className={`lg:absolute object-top ${isMinified && 'rounded border'}`} src={imageURL} alt={title} />
            </div>
          </div>
        </div>
        <div className={`${hasBorders ? 'md:absolute bottom-0 hover:bg-gray-100 bg-white' : ''} w-full flex flex-col justify-center px-4 pb-3 ${isMinified ? 'pt-1.5' : 'pt-2'} ${isPreviewHidden ? '' : 'md:pt-4'} gap-0.5`}>
          <div className={`${isPreviewHidden ? 'text-sm' : 'md:text-lg'} text-gray-600 font-bold md:text-gray-800 md:font-medium`}>{title}</div>
          <div className={`flex-none text-gray-500 text-xs ${isPreviewHidden ? '' : 'md:text-sm'} font-medium flex flex-row flex-wrap items-center gap-1`}>
            {authors && authors.length > 0 && authors[0].name && (
              <div>
                {authors.map(({ name }, i) => (
                  <span key={i}>
                    {name}
                    {i < authors.length - 1 ? ', ' : ''}
                  </span>
                ))}
                <span className="ml-1">|</span>
              </div>
            )}
            {dateString && dateString.length !== 0 && (
              <div>
                <span>{dateString}</span>
                <span className="ml-1">|</span>
              </div>
            )}
            <CommentCount count={commentCount} />
          </div>
          {!isPreviewHidden && <div className="flex-shrink font-normal text-base text-gray-500 leading-6 hidden md:line-clamp-2 lg:line-clamp-3">{descriptionFromMarkdown(content, 300)}</div>}
        </div>
      </div>
    </MobileLink>
  );
};
