import React from 'react';
import { BFFSection } from 'models/BFF';
import RowCell from 'components/bff/RowCell';
import MarketingButton, { MarketingButtonType } from 'components/MarketingButton';

type Props = {
  section: BFFSection;
};

const HomeTournamentsWidget = ({ section }: Props): JSX.Element => {
  return (
    <div className="w-full">
      <div className="flex flex-col rounded border border-gray-200 divide-y divide-gray-200 bg-white">
        {section.rows.map((row, index) => (
          <RowCell key={`row-${index}-${row.type}`} row={row} isCompact={section.isCompact} isFeatured={section.isFeatured} isMobile={false} />
        ))}
        {section.action && (
          <a href={section.action.link}>
            <MarketingButton className="m-4" buttonType={MarketingButtonType.Tournaments} inverted={false}>
              {section.action.title}
            </MarketingButton>
          </a>
        )}
      </div>
    </div>
  );
};

export default HomeTournamentsWidget;
