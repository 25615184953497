import React from 'react';
import { Post } from 'models/Post';
import { ImageWrapped } from './ImageWrapped';
import MobileLink from './MobileLink';
import { getFormattedDateString } from 'helpers/dayjs';
import { CommentCount } from 'components/comments/CommentCount';
import { descriptionFromMarkdown } from '../../helpers/seo';

type Props = {
  post: Post;
  isCompact?: boolean;
  isPrimarilyMobile?: boolean;
};

export const ArticleItemCompact = ({ post: { authors, imageURL, title, url, commentCount, content, date }, isCompact = false, isPrimarilyMobile = false }: Props): JSX.Element => {
  const widthClass = isCompact ? 'w-24' : 'w-24 md:w-36';
  const heightClass = isCompact ? 'h-24' : 'h-24 md:h-36';
  const baseTextClass = isCompact ? 'text-base' : 'text-base md:text-lg';
  const smTextClass = isCompact ? 'text-sm' : 'text-sm md:text-base';
  const dateString = getFormattedDateString(date);

  return (
    <MobileLink href={url.toLowerCase()} isMobile={isPrimarilyMobile}>
      <div className={`bg-white flex flex-row cursor-pointer max-h-full hover:bg-gray-100 border border-gray-200 rounded overflow-hidden p-4 gap-4 font-sans`}>
        <div className={`flex-none ${widthClass} ${heightClass}`}>
          <ImageWrapped shouldShimmer className="rounded" src={imageURL} alt={title} />
        </div>
        <div className={`flex flex-col gap-1 justify-center ${heightClass}`}>
          <div className={`flex-none ${baseTextClass} text-gray-600 font-bold md:text-gray-800 md:font-medium leading-5 line-clamp-2`}>{title}</div>
          <div className={`flex-none text-gray-500 ${smTextClass} font-medium leading-6 flex flex-row flex-wrap items-center gap-1`}>
            {authors && authors.length > 0 && authors[0].name && (
              <div>
                {authors.map(({ name }, i) => (
                  <span key={i}>
                    {name}
                    {i < authors.length - 1 ? ', ' : ''}
                  </span>
                ))}
                <span className="ml-1">|</span>
              </div>
            )}
            {dateString && dateString.length !== 0 && (
              <div>
                <span>{dateString}</span>
                <span className="ml-1">|</span>
              </div>
            )}
            <CommentCount count={commentCount} />
          </div>
          <div className={`flex-shrink text-gray-500 ${smTextClass} line-clamp-1 sm:line-clamp-2 md:line-clamp-3`}>{descriptionFromMarkdown(content, 300)}</div>
        </div>
      </div>
    </MobileLink>
  );
};
